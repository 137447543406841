.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-right: 1rem;
}

.App-header {
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-content {
  display: flex;
  align-items: center;
}

.App-link {
  color:#000000;
  display: block;
  text-decoration: none;
  font-size: 1.0em;
  margin-top: 10px;
  text-align: left;
  font-weight: 350;
}

.App-link:hover {
  color: #0000ff;
}

.title {
  font-weight: bold;
  color: #000000;
  font-size: 2.0em;
}
